import React from "react"
import Layout from "../components/Layout"
import { Router } from "@reach/router"
import Quotes from "../components/Quotes"

const Index = () => (
  <Layout>
    <Router>
      <Quotes path="/quotes/:splat" />
    </Router>
  </Layout>
)

export default Index
